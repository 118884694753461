import React from 'react';


function BtnOffcanvas({event}) {
    
  return (
    <>
    <div className="container-navbar-mobile">
      <div className="nav-btn" id='btn-mobile' onClick={event}>
        <div className="nav-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    </>
  );
}

export default BtnOffcanvas