import React, { useEffect, useState} from 'react'
import '../../scss/components/wppbtn.scss'

const WppBtn = () => {
const [btnWpp, setBtnWpp] = useState(false)

  useEffect(() =>{
    const scrollUp = () =>{
      if(window.scrollY > 150){
        setBtnWpp(true)
      }else{
        setBtnWpp(false)
      }
    }

    window.addEventListener('scroll',scrollUp)

    return ( () =>{
      window.removeEventListener('scroll',scrollUp)
    })

  })


  return (
    <div className={`cont-btnWpp ${btnWpp ? 'active' : ''}`}>
      <a href="https://api.whatsapp.com/send?phone=541163501340&text=Hola!%20%F0%9F%91%8B%0D%0ACómo%20estás?%0D%0AQuiero%20que%20me%20asesores%20sobre%20tus%20servicios!.%0D%0AMuchas%20gracias." target="_blank" rel="noopener noreferrer">
        <i className="fa-brands fa-whatsapp"></i>
      </a>
    </div>
  )
}

export default WppBtn