import React from 'react'
import '../../../scss/components/testimonios.scss'
/* Components */
import Slider from '../../Carrousel/Carrousel.jsx'


const Testimonios = () => {

  return (
    <>
      <div className='title-cont' id='testimonios'>
        <h2 className='title-test'>Testimonios</h2>
      </div>
      <Slider/>
    </>
  )
}

export default Testimonios