import React from 'react'
import '../../scss/components/footer.scss'
import logo from '../../img-src/logo-ilianim-transp.png'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-cont'>
        <a href="#inicio">
          <div className='img-cont'>
            <img src={logo} alt="Logo-footer" />
          </div>
        </a>
        <div className='by-info'>
          <p>Created By 
              <a href="https://codecraftcreations.com.ar/" target="_blank" rel="noopener noreferrer">
                Codecraft
              </a>
            |  2022 © All rights reserved.
          </p>
         
        </div>
        <div className='social-media'>
          <a href="https://www.instagram.com/tyomaila/" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/paolatoi" target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-facebook"></i>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer