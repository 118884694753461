export const dataServ= [
  {
    id:0,
    title:'Sanación Cósmica de Símbolos de Luz',
    img:'img/sanacion-cosmica.png',
    description:'Sanación Cósmica es una técnica holística donde el terapeuta trabaja codo a codo con la jerarquía de luz y también con el médico de 3D, si fuera oportuno. Aquí el paciente recibe una sanación etérica, sutil y cósmica en todos los cuerpos y dimensiones. Es una herramienta de la más alta luz. Las sesiones son personales y con cada una se vive una experiencia amorosa de sanación activando el amor en cada ser. El facilitador de esta técnica trabaja en las causas, desde el más infinito amor de la fuente de Dios. Es una manera sutil de trabajar en la completitud del ser, una experiencia amorosa y llena de luz.',
    consulta:'Consultá por esta maravillosa experiencia de una sanación cósmica con símbolos de luz. Este trabajo se realiza en una sesión. Puede ser virtual o presencial',
    wpp:'Sanaci%C3%B3n%20C%C3%B3smica%20de%20S%C3%ADmbolos%20de%20Luz'
  },

  {
    id:1,
    title:'Sanación Pleyadiana',
    img:'/img/sanacion-pleyadiana.jpg',
    description:'Esta técnica holística es asistida por una conciencia superior, amorosa y empática, conocida como los Pleyadianos. Una conciencia que toma forma de seres multidimensionales, con el fin de impactar en el campo cuántico de la humanidad, elevar la vibración y activar las memorias akáshicas con el fin de despertar la esencia divina que habita en cada rincón de este universo. El contacto con ellos para trabajar esta técnica de sanación es posible, porque estos seres son multidimensionales, es decir que pueden acercarse a la vibración de cualquier dimensión que deseen. Con esta técnica de sanación Pleyadiana logramos una limpieza profunda de las energías estancadas en el alma de la persona, limpiando karmas ancestrales y de vidas pasadas, sacando a la luz todas las cargas emocionales y psíquicas que se encuentran en los archivos del alma a nivel personal y colectivo. A través de la relajación la persona podrá liberarse, desde un nivel inconsciente, de todo lo que la mantiene atada, bloqueada y estancada. Todo aquello que provenga del pasado tendrá la posibilidad de limpiarse, equilibrarse y sanarse para lograr un presente armonioso, basado en el amor, la luz, la paz y la evolución.',
    consulta:'Consultá por una secuencia de 3 sesiones en promoción. Puede ser virtual o presencial.',
    wpp:'Sanaci%C3%B3n%20Pleyadiana'
  },

  {
    id:2,
    title:'Terapia de sonido con cuencos tibetanos',
    img:'./img/cuencos.png',
    description:'Es una técnica de sonido holística, basada en el principio de resonancia, por la cual una vibración más intensa y armónica contagia a otra más débil, disonante o no saludable. Este principio consiste en la capacidad que tiene una frecuencia de modificar a otra frecuencia. También lo hace con las ondas cerebrales ayudándonos a entrar en otros niveles de conciencia, donde son posibles los estados de sanación espontánea, haciéndonos más receptivos a la auto sanación. Cada vez que apoyamos un cuenco en un área del cuerpo y lo tocamos, emite estos sonidos armónicos. Éstos tienen un efecto altamente beneficioso sobre nuestro cuerpo y sobre nuestro campo energético. El cuenco emite frecuencias que están fuera de nuestro campo auditivo, pero emanan tal vibración que resuena en nuestro cuerpo y esa plenitud que se siente, además de darnos felicidad y bienestar, integra todas las áreas desarmónicas y fuera de su equilibrio natural para volver a integrarlas en un todo y en una unidad, pudiendo luego vibrar en una frecuencia mucho más amorosa y compasiva.',
    consulta:'Experimentá una sesión y recibí la magia de los cuencos en una sesión personalizada.',
    wpp:'Terapia%20de%20sonido%20con%20cuencos%20tibetanos'
  },

  {
    id:3,
    title:'Gemalak',
    img:'./img/gemalak.jpg',
    description:'Es una forma de aplicar energía vital a través de cristales físicos y principalmente etéreos. ¿Sabías qué son los cristales etéreos? Son energía sutil, pura y cristalina que se manifiestan en su estado de luz a través de símbolos para ser colocados en los diferentes chakras y así activar todo lo que necesitas para tu bienestar interno. Los cristales físicos cumplen la función de puentes de conexión. Los beneficios de cada aplicación se ven en forma muy rápida y evolutiva, generando cambios y transformaciones bellas y efectivas en tu ser y en tu vida misma. No posee contraindicaciones ya que es una transferencia de energía vital y es apta para todo aquel que desee, en forma espontánea y natural desde su consciencia amorosa, recibir Gemalak. Las sesiones son muy agradables y pueden hacerse en forma presencial o a distancia. Gemalak te abre las puertas a una nueva experiencia de tu ser cristalino a través del apoyo incondicional y asistencia de seres de luz Arcangélicos. Gemalak es una técnica canalizada por Ricardo Guitman.',
    consulta:'Consultá por una secuencia de 3 sesiones en promoción. Esta misma es una sesión personalizada. Puede ser virtual o presencial.',
    wpp:'Gemalak'
  },

  {
    id:4,
    title:'Consultorio',
    img:'./img/consultorio.png',
    description:'¿Necesitás terapia?\n Vení a experimentar una terapia diferente integrando la palabra con lo holístico. Consultá tu problemática a trabajar y abordaremos con las herramientas mencionadas, un trabajo terapéutico exclusivo y singular donde se combinen diferentes sistemas de sanación, según requiera el caso, a través de una sesión personalizada, la cual puede ser de forma virtual o presencial.',
    consulta:'Si necesitas terapia, no dudes en consultar.',
    wpp:'Consultorio'
  },

  {
    id:5,
    title:'Meditación Sonora con Sanación Pleyadiana',
    img:'./img/nuevo-lanzamiento.png',
    description:'Red grupal de armonización con cuencos, más sanación Pleyadiana. Si estás interesado en participar en estos eventos grupales, contáctame y te aviso lugar y fecha del evento.',
    consulta:'Si estás interesado en participar en estos eventos grupales, contáctame y te aviso lugar y fecha del evento.',
    wpp:'Meditación%20Sonora%20con%20Sanación%20Pleyadiana'
  },
  
  {
    id:6,
    title:'Aquaura 13',
    img:'./img/aquaura-13.jpg',
    description:'AquAura 13 es una Técnica de Sanación Estelar donde recibirás Esferas de Energía Cósmica,aplicados a procesos Sanadores y Transformadores en tu ser y en tu vida misma. Cada Esfera contiene Códigos Estelares conectados a una Red de Luz que te enlazan directamente con tus 13 Familias de las Estrellas. Constelación del Águila, Constelación del Cisne, Constelación de Lyra, Constelación del Lobo, Constelación de Tauro, Constelación de Pegasus, Constelación del Triángulo, Constelación de Eridanus, Constelación de Orión, Constelación de Vulpecula, Constelación de Andrómeda, Constelación de la Osa Mayor y Constelación del Delfín. Vivenciarás el Real Tiempo del Universo y Reconectarás a tu Capa 13 del Adn Estelar y Humano de forma multidimensional. En la Conexión con AquAura 13 tendrás la Asistencia permanente desde la Nave Alfa y Omega, Sintiendo la energía pura de Amor y Guía de tu Familia Estelar. AquAura 13 te lleva a conectar con la Energía Creadora Inicial de las Esferas, cuyos Códigos Sanadores Nacen de la Savia de los Árboles de Luz Estelares que a su vez están conectados a tu propio Árbol LUZ de tu cuerpo. AquAura 13 El Despertar de La Esfera, es una Técnica Canalizada y Creada por Ricardo Guitman.',
    consulta:'Informate sobre mi nuevo lanzamiento.',
    wpp:'Aquaura%2013'
  },

]

export const dataCanalizaciones =[
  
  {
    id:0,
    p1:'Mensaje canalizado por Mí de Saint Germain (Maestro Ascendido). La nueva tierra ya está creada, sólo está dentro de la divinidad de cada ser. Para que el divino ser surja en cada uno sólo deben mirarse dentro, en su corazón, donde palpita la llama divina. Esa chispa, esa luz, que los hace únicos, los hace seres de Dios, seres del mar puro y divino amor.',
    p2:'Sólo dejen que lo que hay dentro de ustedes salga a la luz, valga la redundancia. Lo que traerá vida y nacimiento a la nueva tierra es la luz emanada en cada ser que despliegue y expanda su canal más amoroso.',
    p3:'La nueva tierra ya está creada. Sólo deben creer y desplegar las alas, elevarse y vibrar en la frecuencia luminosa que la tierra ya está emanando. Namaste.Saint Germaint.'
  },
  {
    id:1,
    p1:'Pregunto “Arcángel Gabriel ¿cómo puedo expresarme mejor conmigo misma para ser más auténtica y más libre? A veces me enojan ciertas cosas y reacciono con palabras que me tensan aún más? Cómo saber hacer algo mejor?“',
    p2:'El Arcángel Gabriel : -“El don de la palabra es un arma poderosa, con ella puedes hacer el bien, emitiendo palabras de puro amor divino, pero al mismo tiempo también puedes dañar y herir a otros. Usa este don con sapienza y discernimiento.',
    p3:'¿Esto quiere decir que todo el tiempo tienes que ser amable y amoroso? Quiere decir que si algo te enoja y con vehemencia dices algo al respecto, luego vuelve inmediatamente a vibrar en tu verdadera esencia de amor. Lo importante es registrar que te has corrido un poco de tu esencia expresiva original, pero sabes cómo volver porque sabes cuál es la brújula.',
    p4:'El camino de vuelta a tu esencia original. La autenticidad de tu ser tiene que ver con ser lo más fiel posible a tu esencia, esa con la que fuiste creado y que es tu verdadera esencia. La expresión del amor, Namaste”'
  },
  {
    id:2,
    p1:'Canalización lenguaje de Luz. Mensaje canalizado por mí, de mi guía Pleyadiano.',
    p2:'La energía del gran sol central desciende hacia mi ser, extiendo mis manos hacia el universo y recibo su energía que entra por mis manos hacia todo el resto de mí y en forma de espiral limpia y barre cualquier energía mal calificada o residual en mí. Gracias, Gracias, Gracias.',
    p3:'Canción de la Alegría Canalización de mi guía Pleyadiano en leguaje de Luz.'
  },
  {
    id:3,
    p1:'Mensaje del Mago Merlín Co-crea lo que deseas manifestar. Enfoca tu cuerpo y co-crea en tu mente, visualízalo o escríbelo en el aire, luego lo tomas y lo traes a tu cuerpo impregnándolo en él. Primero co-creas y luego lo tomas y enraízas en tí. Así de simple. Así de fácil. Pruébalo y nunca olvides que la magia está y existe en tí.',
    p2:'Sólo debes creer en ella. Ella espera por tí a que tú la enciendas y la utilices con fe y amor. Siempre para el mayor bien y si quieres encender la magia en otros nunca olvides que todos tienen esa magia adentro suyo. Porque todos son verdaderos magos.',
    p3:'Pero cada quién tiene su tiempo. Y ese tiempo es personal. Se tú la luz y lleva a otros la chispa del amor y eso hará que muchos otros seres también lo vibren y lo sientan. Todos somos uno. En amor en luz en Dios.'
  },
]