import React from 'react'
import '../../scss/components/inicio.scss'
import perfil from '../../img-src/perfil-pao.jpeg'

const Inicio = () => {
  return (
    <>
      <section className='inicio' id='inicio'>
        <div className='inicio-cont'>
          <h2 className='incio-title'>¡Hola!</h2>
          <div className='title-cont'>
            <h2 className='title'>Sobre mí</h2>
          </div>
          <div className='p-cont'>
            <p>Mi nombre es Paola Toimaher, soy Psicóloga y mi propuesta es integrar el conocimiento que traigo desde la ciencia en el área de la Psicología, a través de la palabra con el paciente, con el conocimiento que nos trae la divinidad espiritual a través de prácticas holísticas.</p>
            <p>Unir el cielo y la tierra, ser un puente de integración y trabajar abarcando tanto el campo psicológico y el campo espiritual, tomando al ser como un todo integral en donde pueda encontrarse a sí mismo en su propia verdad.</p>
          </div>
          <div className='btn-cont'>
            <a href="#servicios">
              <button>Conocé más de lo que hago</button>  
            </a> 
          </div>
        </div>
      </section>

      <div>
        <div className='svg-diagonal' style={{height:'150px',overflow:"hidden"}} ><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height:'100%',width:'100%'}}><path d="M-20.03,153.47 C161.68,108.06 333.23,47.88 582.67,-24.16 L504.79,158.39 L-18.90,154.45 Z" style={{stroke:'none',fill:'#80ED99'}}></path></svg></div>
        <section className='trabajo'>
          <div className='trabajo-cont'>
            <div className='img-cont'>
              <img src={perfil} alt="Rostro-pao" />
            </div>
            <div className='text-cont'>
              <div className='title-cont'>
                <h3 className='title-trabajo'>¿A qué me dedico?</h3>
              </div>
              <div className='p-cont-tb'>
                <p>Integro técnicas de sanación con sesiones de terapia psicoanalítica. Las técnicas holísticas son realizadas codo a codo con asistencia de los médicos del cielo y de toda la hermandad blanca de Luz junto con los Seres Estelares. Además, trabajo en forma interdisciplinaria con los médicos de aquí en la tierra.</p>
              </div>
              <div className='btn-cont-tb'>
                <a href="#contacto">
                  <button>Comunícate conmigo</button>  
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>

  )
}

export default Inicio