import { useEffect, useState } from 'react';
import './App.scss';
/* Pages */
import Page1 from './components/Page-1/Page1';
import Page2 from './components/Page-2/Page2'
/* React Router */
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
/* React-Bootstrap */
import Spinner from 'react-bootstrap/Spinner';



function App() {
  const [animation, setAnimation] = useState(false)

  useEffect(() =>{
    document.body.style.overflow = "hidden"
    setTimeout(() =>{
      setAnimation(true)
      document.body.style.overflow = "visible"
    },2000)
  },[])

  return (
    <>
      <div className={`${animation ? 'animation-active' : 'loader-container'}`}>
        <Spinner animation="grow" />
      </div>
      <BrowserRouter>
        {/* <Header/> Tengo que hacer así para que me ande el loader */}
        <Routes>
          <Route path='/' element={<Page1/>}/>
          <Route path='/canalizacion' element={<Page2/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
