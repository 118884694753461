import React from 'react'
import Navbar from './Navbar.jsx'
import '../../scss/components/header.scss'
/* React  - Router Dom */
import { useLocation } from 'react-router-dom'

const Header = () => {
  const location = useLocation()
  
  return (
    <header className='header'>
      <Navbar/>
      {
        location.pathname !== '/canalizacion'?
          <>
            <div className='cont-imgHome'></div>
            <div className='cont-title'>
              <h1 className='title-home'>Dar Luz a la conciencia para liberar y sanar los cuerpos en todos sus planos y en todas sus dimensiones</h1>
            </div>
          </>
          :
          null
      }
      
    </header>
  )
}

export default Header