import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
/* EmialJS */
import emailjs from '@emailjs/browser'
/* SweetAlert */
import Swal from 'sweetalert2'

function FormContact() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      event.preventDefault();
      emailjs.sendForm('service_asg8uu7', 'template_o9rwpao', event.target,'RLEsq0U4s2boRJskU')
      .then(res => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Se envío correctamente!'
        })
      }).catch(err => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'error',
          title: 'Hubo un problema, vuelve a intentarlo!'
        })
      })
    }
    
    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-5">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <FloatingLabel
            controlId="floatingInput"
            label="Nombre"
          >
            <Form.Control
              required
              type="text"
              placeholder="Nombre"
              name='user_name'
            />
            <Form.Control.Feedback>
              El nombre es válido!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              El nombre no es válido.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <FloatingLabel
              controlId="floatingInput"
              label="Apellido"
            >
            <Form.Control
              required
              type="text"
              placeholder="Apellido"
              name='user_surname'
            />
            <Form.Control.Feedback>
              El apellido es válido!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              El apellido no es válido.
            </Form.Control.Feedback>
          </FloatingLabel> 
        </Form.Group>
      </Row>

      <Row className="mb-5">
      <Form.Group as={Col} md="6" controlId="validationCustom03">
          <FloatingLabel
            controlId="floatingInput"
            label="Email"
          >
            <Form.Control
              required
              type="email"
              placeholder="Email"
              name='user_email'
            />
            <Form.Control.Feedback>
              El email es válido!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              El email no es válido.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationCustom04">
          <FloatingLabel
              controlId="floatingInput"
              label="Celular"
            >
            <Form.Control
              required
              type="number"
              placeholder="Celular"
              name='user_phone'
            />
            <Form.Control.Feedback>
              El celular es válido!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              El celular no es válido.
            </Form.Control.Feedback>
          </FloatingLabel> 
        </Form.Group>
      </Row>

      <Row className='mb-5'>
      <Form.Group as={Col} md="12" controlId="validationCustom04">
          <FloatingLabel
              controlId="floatingTextarea"
              label="Mensaje"
            >
            <Form.Control
              required
              as="textarea"
              placeholder="Mensaje"
              name='user_message'
            />
            <Form.Control.Feedback>
              El mensaje es válido!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              El mensaje no es válido.
            </Form.Control.Feedback>
          </FloatingLabel> 
        </Form.Group>
      </Row>

      <Button type="submit">
        <div className='bg-btn'>
          <span>Enviar</span>
          <i className="fa-solid fa-arrow-right"></i>
        </div>
        Enviar mensaje
      </Button>
    </Form>
  );
}

export default FormContact