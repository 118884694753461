import React from 'react'
import '../../../scss/components/logo.scss'
import logo from '../../../img-src/logo-ilianim-transp.png'

const Logo = () => {
  return (
    <>
    <section>
      <div className='svg-diagonal' style={{height:'150px',overflow:"hidden"}} ><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height:'100%',width:'100%'}}><path d="M-20.03,153.47 C161.68,108.06 333.23,47.88 582.67,-24.16 L504.79,158.39 L-18.90,154.45 Z" style={{stroke:'none',fill:'#80ED99'}}></path></svg></div>
      <section className='logo-section' id='logo'>
        <div className='logo-cont'>
          <div className='title-cont'>
            <h2 className='title-logo'>Nombre y Logo</h2>
          </div>
          <div className='img-cont'>
            <a href="#inicio"><img src={logo} alt="logo-significado"/></a>
          </div>
          <div className='text-cont'>
            <p>En una meditación canalicé al mago Merlín ya que tengo mucha conexión con él. Venía activando memorias de vidas pasadas y en una oportunidad recordé mi vida en la época del Rey Arturo, de ahí mi conexión con Merlín.</p>
            <p>Cuando conecté con él, en una meditación visualizé imágenes sobre esa vida y me mostró un símbolo que tenía tatuado como sacerdotisa que fui en esa época. El tatuaje lo usaron todas las sacerdotisas y luego vino a mí el nombre. Merlín me lo facilitó en esa meditación que realicé.</p>
            <p>Así fue como dibujé el símbolo que hoy es el logo de mi página que va junto con el nombre, rememorando y haciendo honor a quien fui en aquella vida. Recordar y activar las memorias, me permitió integrar quien fui y quien soy hoy, todo en unidad conmigo misma y con mi verdad. Desde aquí vibro, desde aquí SOY!</p>
          </div>
        </div>
      </section>
    </section>
    </>
  )
}

export default Logo