import Carousel from 'react-bootstrap/Carousel';

function Slider() {

  return (
    <Carousel>
      <Carousel.Item>
        <div className='slider' div='true'>
          <div className='const-slider'>
            <div className='cont-name'>
              <h3>Luciana Graziano</h3>
            </div>
            <div className='cont-testimony'>
              <p>" Gracias al acompañamiento y al profesionalismo de Paola y a las técnicas que suma y va alternando en el tratamiento, como sanación cósmica, sanación pleyadiana, del árbol genealógico etc, he logrado liberar muchas situaciones arraigadas, personales y familiares que ni siquiera sabía que las tenía. Gracias por tanto! "</p>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='slider' div='true'>
          <div className='const-slider'>
            <div className='cont-name'>
              <h3>Uri Trajtemberg</h3>
            </div>
            <div className='cont-testimony'>
              <p>" Consulté a Paola porque estaba deprimido, angustiado, trabado con mi vida. Hicimos unas sesiones con sanación Pleyadiana y surgieron cosas interesantes en relación con mi padre ya fallecido del cual estaba enlazado y muchas cosas con él se jugaron en mi estado actual. Se pudieron liberar y hoy me siento mucho mejor. Ahora estamos trabajando con mis centros de energía, mis chakras, aprendí muchas cosas sobre cómo vibran en uno y voy aprendiendo a conocerme y darme espacios para mí. Me siento en un período de más conexión conmigo y aprendo día a día a escucharme. "</p>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='slider' div='true'>
          <div className='const-slider'>
            <div className='cont-name'>
              <h3>Mariela Muracciole</h3>
            </div>
            <div className='cont-testimony'>
              <p>" Quiero compartir mi experiencia personal lograda con Paola como terapeuta dado que con su método en el cual combina la terapia convencional con las prácticas holísticas ha logrado en mí un cambio significativo en mi bienestar emocional y auto conocimiento. Su técnica profesional se amolda perfectamente a mi búsqueda personal. "</p>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;