import React, { useEffect, useState } from 'react'
/* Components */
import WppBtn from '../WppBtn/WppBtn.jsx'
import ScrollUpBtn from "../ScrollUpBtn/ScrollUpBtn.jsx"
import Logo from './Logo/Logo.jsx'
import Canalizacion from './Canalizacion/Canalizacion.jsx'
import Testimonios from './Testimonios/Testimonios.jsx'
import Footer from '../Footer/Footer.jsx'
import Header from '../Inicio/Header.jsx'
/* React-Bootstrap */
import Spinner from 'react-bootstrap/Spinner'
/* Helmet */
import { Helmet } from 'react-helmet'

const Page2 = () => {
  const [animation, setAnimation] = useState(false)

  useEffect(() =>{
    document.body.style.overflow = "hidden"
    setTimeout(() =>{
      setAnimation(true)
      document.body.style.overflow = "visible"
    },2500)
  },[])

  return (
    <>
      <div className={`${animation ? 'animation-active' : 'loader-container'}`}>
        <Spinner animation="grow" />
      </div>
      <Helmet>
        <title>{!animation ? 'Cargando...' : 'Blog | Canalizaciónes'}</title>
        <meta name='keywords' content='Holistica, psicologia, sanacion, tecnicas, canalizacion, pacientes, ilianim'/>
        <meta name='description' content='La canalización espiritual es el acto de conectar con un ser de luz de alta vibración para recibir su orientación y sus mensajes.'/>
      </Helmet>
      <WppBtn/>
      <ScrollUpBtn/>
      <Header/>
      <Logo/>
      <Canalizacion/>
      <Testimonios/>
      <Footer/>
    </>
  )
}

export default Page2