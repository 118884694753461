import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import '../../scss/components/scrollbtn.scss'

const ScrollUpBtn = () => {
  const [btnScroll, setBtnScroll] = useState(false)
  const location = useLocation();



  useEffect(() =>{

    handleClick()
    
    const scrollUp = () =>{
      if(window.scrollY > 1550){
        setBtnScroll(true)
      }else{
        setBtnScroll(false)
      }
    }

    window.addEventListener('scroll',scrollUp)

    return ( () =>{
      window.removeEventListener('scroll',scrollUp)
    })

  },[location.pathname])

  const handleClick = () =>{
    window.scrollTo({
      top:0,
      behavior:'smooth'
    })
  }


  return (
    <div className={`cont-btnScroll ${btnScroll ? 'active' : ''}`} onClick={handleClick}>
      <i className="fa-regular fa-square-caret-up"></i>
    </div>
  )
}

export default ScrollUpBtn