import React , { useEffect, useState } from 'react'
import noLoad from '../../../img-src/no-carga.png'
/* React - Bootstrap */
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

const ServiciosItem = ({servicio}) => {
  const [open, setOpen] = useState(false);
  const [descRecort, setDescRecort] = useState()
  //const [descComplete, setDescComplete] = useState([])
  const arrayWords = servicio.description.split(' ')

  useEffect(() =>{
    if(arrayWords.length > 100){
      const res = arrayWords.splice(0,50).join(' ')
      setDescRecort(res + ' . . .')
      //setDescComplete(arrayWords.join(' '))
    }else{
      setDescRecort(servicio.description)
    }
  },[arrayWords,setDescRecort,/* setDescComplete, */servicio,open])
  
  return (

    <>
      <div className='item'>
        <h3 className='title-item'>{servicio.title}</h3>
        <div className='cont-info'>
          <div>
            <img src={servicio.img ? servicio.img : noLoad} alt={`IMG-${servicio.title}`} />
          </div>
          <div>
            {
              servicio.title === 'Gemalak' ? <h5>¿Qué es?</h5> : null
            }
            {
              open ? null : <p>{descRecort}</p>
            }
            <Collapse in={open}>
              <p id="text-restante">
                {servicio.description}
              </p>
            </Collapse>
            {
              arrayWords.length > 100 ?
                <Button
                  onClick={() => setOpen(!open)}
                  aria-controls="text-restante"
                  aria-expanded={open}
                  className='btn-verMas'
                >
                  Ver más...
                </Button>
                
            :
              null
            }
          </div>
        </div>
      </div>
      {
        open ?

          <div data-balloon-visible aria-label="Asesorate haciendo click aquí!" data-balloon-pos="down-left" className='btn-consulta'>
            <a href={`https://api.whatsapp.com/send?phone=+1163501340&text=Hola!%20%F0%9F%91%8B%0D%0ACómo%20estás?%0D%0AMi%20consulta%20es%20a%20trav%C3%A9s%20del%20servicio%20%F0%9F%94%B8${servicio.wpp}%20%F0%9F%94%B8.%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20mismo.%0D%0AMuchas%20gracias.`} target="_blank" rel="noopener noreferrer">
              {servicio.consulta ? servicio.consulta : 'CONSULTÁ'}
            </a>
          </div>
        :

          <div aria-label="Asesorate haciendo click aquí!" data-balloon-pos="down-left" className='btn-consulta'>
            <a href={`https://api.whatsapp.com/send?phone=541163501340&text=Hola!%20%F0%9F%91%8B%0D%0ACómo%20estás?%0D%0AMi%20consulta%20es%20a%20trav%C3%A9s%20del%20servicio%20%F0%9F%94%B8${servicio.wpp}%20%F0%9F%94%B8.%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20mismo.%0D%0AMuchas%20gracias.`} target="_blank" rel="noopener noreferrer">
              {servicio.consulta ? servicio.consulta : 'CONSULTÁ'}
            </a>
          </div>
      }
    </>
  )
}

export default ServiciosItem