import React, { useEffect, useState } from 'react'
import '../../scss/components/navbar.scss'
import logo from '../../img-src/logo-ilianim-transp.png'
/* React  - Bootstrap */
import BtnOffcanvas from '../BtnOffcanvas/BtnOffcanvas'
/* React  - Router Dom */
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {

  const [scrollState, setScrollState] = useState(false)
  const [activeLink, setActiveLink] = useState('inicio')
  const [btnActive, setBtnActive] = useState(false);
  const {pathname} = useLocation();


  useEffect(() =>{

    const onScroll = () =>{
      if(window.scrollY > 10){
        setScrollState(true)
      }else{
        setScrollState(false)
      }
    }
    
    window.addEventListener('scroll' , onScroll)

    return () => {
      window.removeEventListener('scroll',onScroll)
    }

  },[])

  
  const handlerMenu = () =>{
    const btn = document.getElementById('btn-mobile')
    if(btn.classList.toggle('active')){
      setBtnActive(true)
    }else{
      setBtnActive(false)
    }
  }


  const onUpdateActiveLink = (linkValue) =>{
    setActiveLink(linkValue)
    const btn = document.getElementById('btn-mobile')
    if(btnActive){
      setBtnActive(false)
      btn.classList.remove('active')
    }
  }

  const hiddenMenu = () =>{
    const btn = document.getElementById('btn-mobile')
    btn.classList.remove('active')
    setBtnActive(false)
  }

  return (
    
    <>
      <div className={`offcanvas-background ${btnActive ? 'active' : ''}`} onClick={hiddenMenu}></div>
      <div className="menu-offcanvas" style={btnActive ? {visibility:'visible',left:'-0%'} : {visibility: 'hidden'}}>
        <ul className='nav-list'>
          {
            pathname === '/canalizacion' ?
            <>
              <li className={activeLink === 'inicio' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('inicio')}><Link to="/"><span>Inicio</span></Link></li>              
              <li className={activeLink === 'logo' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('logo')}><a href="#logo"><span>Logo</span></a></li>
              <li className={activeLink === 'misCanalizaciones' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('misCanalizaciones')}><a href="#misCanalizaciones"><span>Mis canalizaciones</span></a></li>
              <li className={activeLink === 'testimonios' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('testimonios')}><a  href="#testimonios"><span>Testimonios</span></a></li>              
            </>
            :
            <>
              <li className={activeLink === 'inicio' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('inicio')}><a href="#inicio"><span>Inicio</span></a></li>
              <li className={activeLink === 'canalizacion' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('canalizacion')}><Link to="/canalizacion"><span>Canalización</span></Link></li>
              <li className={activeLink === 'servicios' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('servicios')}><a href="#servicios"><span>Servicios</span></a></li>
              <li className={activeLink === 'contacto' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('contacto')}><a href="#contacto"><span>Contacto</span></a></li>
            </>
          }
        </ul>
      </div>

      <nav className={`nav ${scrollState ? 'scroll' : ''}`}>
        <div className='nav-cont'>
          <a href={pathname === '/canalizacion' ? '#logo' : '#inicio'}>  
            <div className='cont-logo'>
              <img src={logo} alt="Logo-ilianim"/>
            </div>
          </a>
          <ul className='nav-ul'>
            {
              pathname === '/canalizacion' ?
              <>
                <li className={activeLink === 'inicio' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('inicio')}><Link to="/"><span>Inicio</span></Link></li>
                <li className={activeLink === 'logo' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('logo')}><a href="#logo"><span>Logo</span></a></li>
                <li className={activeLink === 'misCanalizaciones' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('misCanalizaciones')}><a href="#misCanalizaciones"><span>Mis canalizaciones</span></a></li>
                <li className={activeLink === 'testimonios' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('testimonios')}><a href="#testimonios"><span>Testimonios</span></a></li>              
              </>
              :
              <>
                <li className={activeLink === 'inicio' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('inicio')}><a href="#inicio"><span>Inicio</span></a></li>
                <li className={activeLink === 'canalizacion' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('canalizacion')}><Link to="/canalizacion"><span>Canalización</span></Link></li>
                <li className={activeLink === 'servicios' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('servicios')}><a href="#servicios"><span>Servicios</span></a></li>
                <li className={activeLink === 'contacto' ? 'active nav-li' : 'nav-li'} onClick={() => onUpdateActiveLink('contacto')}><a href="#contacto"><span>Contacto</span></a></li>
              </>
            }
          </ul>
          <div className='nav-mobile'>
            <BtnOffcanvas event={handlerMenu}/>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar