import React from 'react'
import '../../../scss/components/contacto.scss'
/* Components */
import FormContact from '../../Footer/FormBt.jsx'

const Contacto = () => {
  return (
    <section className='contacto' id='contacto'>
      <div className='contacto-cont'>
        <div className='title-cont'>
          <h2 className='title-contacto'>Contacto</h2>
        </div>

        <div className='cont-info-personal'>
          <div>
            <i className="fa-solid fa-location-dot"></i>
            <p>Buenos Aires, Belgrano</p>
          </div>
          <div>
            <i className="fa-solid fa-globe"></i>
            <p>paolatoi @hotmail.com</p>
          </div>
          <div>
            <i className="fa-solid fa-phone"></i>
            <p>11 6350-1340</p>
          </div>
        </div>
        
        <div className='cont-form'>
          <FormContact/>
        </div>
      </div>
    </section>
  )
}

export default Contacto