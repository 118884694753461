import React from 'react'
import '../../../scss/components/canalizacion.scss'

const itemsCanalizaciones = ({canalizacion}) => {
  return (
    <div className='item'>
      <div className='icon-comillas'>
        <i className="fa-solid fa-quote-left"></i>
      </div>
      <div className='desc-cont'>
        <p>{canalizacion.p1}</p>
        {
          canalizacion.id === 2 ?
          <div className='cancion'>
            <span>Jofi jofi itzalui</span>
            <span>Itzalui aven</span>
            <span>Itzalui aven</span>
          </div>
          :
          null
        }
        <p>{canalizacion.p2}</p>
        <p>{canalizacion.p3}</p>
        {
           canalizacion.id === 2 ?
           <div className='cancion'>
             <span>Salom salom om shasuki (2 veces)</span>
             <span>Suki suki on a eh</span>
             <span>Suki suki</span>
             <span>On a eeehhh</span>
           </div>
           :
           null
        }
        <p>{canalizacion.p4}</p>
      </div>
    </div>
  )
}

export default itemsCanalizaciones