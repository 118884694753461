import React, { useEffect, useState } from 'react'
/* Components */
import Contacto from './Contacto/Contacto.jsx'
import Inicio from '../Inicio/Inicio'
import Servicios from './Servicios/Servicios'
import ScrollUpBtn from '../ScrollUpBtn/ScrollUpBtn.jsx'
import WppBtn from '../WppBtn/WppBtn.jsx'
import Footer from '../Footer/Footer.jsx'
import Header from '../Inicio/Header.jsx'
/* React-Bootstrap */
import Spinner from 'react-bootstrap/Spinner';
/* Helmet */
import { Helmet } from 'react-helmet'


const Page1 = () => {
  const [animation, setAnimation] = useState(false)

  useEffect(() =>{
    document.body.style.overflow = "hidden"
    setTimeout(() =>{
      setAnimation(true)
      document.body.style.overflow = "visible"
    },2000)
  },[])

  return (
    <>
      <div className={`${animation ? 'animation-active' : 'loader-container'}`}>
        <Spinner animation="grow" />
      </div>
      <Helmet>
        <title>{!animation ? 'Cargando...' : 'Blog | Holística Ilianim'}</title>
        <meta name='keywords' content='Holistica, psicologia, sanacion, tecnicas, sanacion cosmica, sanacion pleyadiana, cuencos tibetanos, gemalak, consultorio, ilianim'/>
        <meta name='description' content='Mi nombre es Paola Toimaher, soy Psicóloga y mi propuesta es integrar el conocimiento que traigo desde la ciencia en el área de la Psicología, a través de la palabra con el paciente, con el conocimiento que nos trae la divinidad espiritual a través de prácticas holísticas.'/>
      </Helmet>
      <WppBtn/>
      <ScrollUpBtn/>
      <Header/>
      <Inicio/>
      <Servicios/>
      <Contacto/>
      <Footer/>  
    </>
  )
}

export default Page1