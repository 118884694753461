import React from 'react'
import '../../../scss/components/canalizacion.scss'
import Simbolo from '../../../img-src/simbolo-pao.png'
/* Data */
import {dataCanalizaciones} from '../../../data.jsx'
/* Items */
import ItemsCanalizaciones from './itemsCanalizaciones.jsx'

const Canalizacion = () => {
  return (
    <>
      <section className='canalizaciones' id='misCanalizaciones'>
        <div className='canalizaciones-cont'>
          <div className='title-cont'>
            <h2 className='title-can'>Canalización</h2>
          </div>
          <div className='explicacion-can'>
            <div className='text-exp'>
              <p>La canalización espiritual es el acto de conectar con un ser de luz de alta vibración para recibir su orientación y sus mensajes. Llamamos seres de luz de alta vibración a los guías espirituales que pueden ser ángeles, arcángeles, maestros ascendidos o personas que fallecieron y se fueron a la luz, entre otros.</p>
              <p>Las canalizaciones se manifiestan en las sesiones personalizadas con pacientes, ya sea en terapias de sanación como en terapias de sonidos con cuencos.</p>
            </div>
          </div>
          <div className='mis-canalizaciones'>
            <div className='title-misCan'>
              <h2>Mis canalizaciones</h2>
            </div>
            <div className='cont-items'>
              {
                dataCanalizaciones.map(can =>{
                  return <ItemsCanalizaciones key={can.id} canalizacion={can}/>
                })
              }
            </div>
          </div>
          <div className='simbolo-frase'>
            <div className='cont-simbolo-frase'>
              <img src={Simbolo} alt="simbolo" />
              <div className='cont-frase'>
                <h4>IM JA AIA</h4>
                <h4>"ERES TU MISMO"</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Canalizacion