import React from 'react'
import '../../../scss/components/servicios.scss'
/* Data */
import { dataServ } from '../../../data.jsx'
/* Items */
import ServiciosItem from './ServiciosItem.jsx'


const Servicios = () => {
  return (
    <section className='servicios' id='servicios'>
      <div className='servicios-cont'>
        <div className='title-cont'>
          <h2 className='title-serv'>Servicios</h2>
        </div>
        <div className='items-cont'>

          {
            dataServ.map(serv =>{
              return <ServiciosItem key={serv.id} servicio={serv}/>
            })
          }

        </div>
      </div>
    </section>
  )
}

export default Servicios